/* HeroPage.css */

.hero-container {
  position: relative;
  height: 100vh;
  background-image: url("/src/components/assets/hero1 (1).png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Ensure content doesn't overflow */
  padding-top: 150px;
  border-top: 5px;
}

.hero-content {
  position: relative; /* Changed from absolute to relative */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: rgba(0, 0, 0, 0.5); /* Apply the overlay background here */
  padding: 20px;
  border-radius: 8px;
  z-index: 2; /* Ensure it layers correctly */
}

.hero-title {
  margin-bottom: 20px;
}

.hero-title-text {
  color: wheat;
  font-weight: 800;
  font-size: xx-large;
}

.hero-subtitle {
  margin-bottom: 40px;
  padding-top: 10px;
  font-family: "Courier New", Courier, monospace;
  color: yellow;
}

.hero-button {
  color: white;
  padding: 10px 20px;
  font-size: 16px;
}
