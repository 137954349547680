.church-footer {
  background-color: #004d40; /* Sharp, vibrant green color */
  color: aliceblue;
  padding: 20px;
  text-align: center;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-info,
.footer-contact,
.footer-designed-by {
  margin-bottom: 20px;
  width: 100%;
}

.footer-info h3,
.footer-contact h3,
.footer-designed-by h3 {
  margin: 10px 0;
  color: #ffeb3b; /* Yellow color for the headers */
}

.footer-info p,
.footer-contact p,
.footer-designed-by p {
  margin: 0 0 10px 0;
}

.social-icons {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.social-icons li {
  display: inline;
}

.footer-contact ul,
.footer-designed-by ul {
  list-style-type: none;
  padding: 0;
  padding-top: 10px;
}

.footer-contact li,
.footer-designed-by li {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  word-wrap: break-word; /* Ensure long words break to the next line */
}

.footer-contact li i,
.footer-designed-by li i {
  margin-right: 10px;
}

.copyright {
  margin-top: 20px;
  font-size: 14px;
  font-family: monospace;
  color: red;
}

.typewriter {
  font-size: 14px;
  font-family: monospace;
  color: yellow;
}

a {
  text-decoration: none;
  color: #ffeb3b; /* Yellow color for links */
  margin-left: 4px;
  word-break: break-all; /* Ensure long words break to fit within the container */
}

@media (min-width: 937px) {
  .footer-content {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .footer-info,
  .footer-contact,
  .footer-designed-by {
    width: 30%;
    text-align: left;
  }

  .footer-contact li,
  .footer-designed-by li {
    justify-content: flex-start;
  }
}

@media (max-width: 934px) {
  .footer-content {
    display: block;
    flex-direction: column;
    align-items: center;
  }

  .footer-info,
  .footer-contact,
  .footer-designed-by {
    width: 100%;
    text-align: center;
  }

  .footer-contact li,
  .footer-designed-by li {
    justify-content: center;
  }

  .footer-contact li a,
  .footer-designed-by li a {
    word-break: break-all; /* Break long words to prevent overflow */
  }
}

@media (max-width: 600px) {
  .footer-info h3,
  .footer-contact h3,
  .footer-designed-by h3 {
    font-size: 18px;
  }

  .social-icons {
    gap: 5px;
  }

  .footer-contact li,
  .footer-designed-by li {
    flex-direction: column;
    align-items: center;
    word-break: break-all; /* Break long words to prevent overflow */
  }
}
